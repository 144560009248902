<template>
  <div class="group-buttons">
    <b-button
      size="is-small"
      type="is-warning"
      tag="router-link"
      :to="routeUpdate"
    >
      Редактировать
    </b-button>
    <b-button
      size="is-small"
      type="is-danger"
      @click.prevent="deleteClient(id)"
    >
      Удалить
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'GroupButtons',
  props: ['id', 'type'],
  computed: {
    routeUpdate() {
      return `/update-client/${this.type}/${this.id}`;
    },
  },
  methods: {
    async deleteClient(id) {
      const success = await this.$store.dispatch('client/deleteClient', {
        id,
      });

      if (success) {
        setTimeout(() => {
          window.top.location.href = '/workspace';
        }, 2000);
      }
    },
    async updateClient(id) {
      const success = await this.$store.dispatch('client/updateClient', {
        id,
      });

      if (success) {
        setTimeout(() => {
          window.top.location.href = '/workspace';
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.group-buttons {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 2px solid #496ca2;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;
}
</style>
